.login_page {
    background-color: rgb(51 62 76);
    min-height: 100vh;
}

.login_box {
    max-width: 24em;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 10em;
    margin-left: auto;
    margin-right: auto;
}

.login_button {
    color: white;
    background-color: transparent;
    border: 1px solid white;
}

.login_button:hover {
    color: rgb(51 62 76);
    background-color: white;
    border: 1px solid white;
}

.logo_image {
    margin: auto auto;
    width: 50%;
    height: 40%;
    display: block;
}

.title {
    color: white;
    margin: 20px 0px;
}
